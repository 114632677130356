import { cartAPI, homeAPI, productAPI } from '@api';
import { CartNotification, ProductSection } from '@components';
import {
  DEFAULT_CLASSIFICATION_PARAMS,
  DEFAULT_PRODUCT_PARAMS,
  PATH_CART,
  PATH_PRODUCT,
} from '@configs';
import {
  Attribute,
  CartItem,
  CartPayload,
  CartUpdateResponse,
  Classification,
  ProductDetailPayload,
  ProductDetailResponse,
  ProductDetailRoot,
  ProductFilter,
  ProductFilterRoot,
  ProductsHotSelling,
  SubClassification,
} from '@interfaces';
import { selectAuth, useAppDispatch, useAppSelector } from '@redux';
import { LogApp, toVND, useMediaQuery } from '@utils';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { decreaseQuantity, increaseQuantity } from 'src/redux/Slices/cart';
import { selectHome, setCurrentParentProduct } from 'src/redux/Slices/home';
import { useImmer } from 'use-immer';
import ImageGallery from 'react-image-gallery';
const MAX_ITEM = 10000;
export const ProductModule = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { privateId } = useAppSelector(selectAuth);
  const { currentChildCategory, currentWarehouse } = useAppSelector(selectHome);
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [sameProducts, setSameProducts] = useState<ProductFilter[]>([]);
  const [error, setError] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const galleryRef = useRef<ImageGallery>(null);
  const [selectedClassification, setSelectedClassification] = useState<Classification>(
    DEFAULT_CLASSIFICATION_PARAMS,
  );
  const [selectedSubClassification, setSelectedSubClassification] = useState<SubClassification>(
    DEFAULT_CLASSIFICATION_PARAMS,
  );
  const [product, setProduct] = useState<ProductDetailResponse>(DEFAULT_PRODUCT_PARAMS);
  const [modalData, setModalData] = useImmer({
    product: DEFAULT_PRODUCT_PARAMS,
    opened: false,
  });
  const onInteractAddProductModal = (value: boolean) => {
    setModalOpened(value);
  };
  const getProduct = async () => {
    try {
      setIsLoading(true);
      const payload: ProductDetailPayload = {
        id: id || '',
        view: 1,
      };
      // const res: ProductDetailRoot = await productAPI.getProduct(payload);
      const res: ProductDetailRoot = await productAPI.getProductByCode(
        id || '',
        currentWarehouse.id,
      );
      const categoryId =
        res.data.categories?.[0]?.sub_categories?.[0].id || res.data.categories?.[0].id;
      await getProductBySubCategory(categoryId);
      setProduct(res.data);
      dispatch(setCurrentParentProduct(res.data));
      if (res.data.classifications.length > 0)
        setSelectedClassification(res.data.classifications?.[0]);
      setIsNotFound(false);
    } catch (error: any) {
      setIsNotFound(error.status === 400);
    } finally {
      setIsLoading(false);
    }
  };
  const onSelectClassification = (classification: Classification) => {
    setSelectedClassification(classification);
    slideToIndex(classification);
  };
  const onSelectSubClassification = (classification: SubClassification) => {
    setSelectedSubClassification(classification);
  };
  const getProductDeduction = () => {
    if (product.classifications.length > 0) {
      const hasSubClassification = product.classifications.every(
        (item) => item.sub_classifications.length > 0,
      );
      if (!hasSubClassification) {
        return product.classifications.at?.(0)?.product_item.deduct_percent ?? 0;
      }
      return (
        product.classifications.at?.(0)?.sub_classifications?.at?.(0)?.product_item
          ?.deduct_percent ?? 0
      );
    }
    return product.deduct_percent;
  };

  const getProductPrice = () => {
    const percentage = 1 - getProductDeduction() / 100;
    if (product.classifications.length > 0) {
      const hasSubClassification = product.classifications.every(
        (item) => item.sub_classifications.length > 0,
      );
      if (
        (!selectedSubClassification.id && hasSubClassification) ||
        (!selectedClassification.id && !hasSubClassification)
      ) {
        const maxPrice = findMinMaxPrices(product).maxPrice;
        const minPrice = findMinMaxPrices(product).minPrice;
        if (maxPrice === minPrice) return toVND(minPrice);
        return `${toVND(minPrice)} - ${toVND(maxPrice)}`;
      }
      if (!hasSubClassification) {
        return toVND(selectedClassification.product_item?.selling_price * percentage);
      }
      return toVND(selectedSubClassification?.product_item?.selling_price * percentage);
    }
    return toVND(product?.selling_price * percentage);
  };
  const findMinMaxPrices = (data: ProductDetailResponse) => {
    let minPrice = Infinity;
    let maxPrice = -Infinity;
    data.classifications.forEach((classification) => {
      if (classification.sub_classifications.length)
        classification.sub_classifications.forEach((subClassification) => {
          const sellingPrice = subClassification.product_item.selling_price;
          minPrice = Math.min(minPrice, sellingPrice);
          maxPrice = Math.max(maxPrice, sellingPrice);
        });
      else {
        const sellingPrice = classification.product_item.selling_price;
        minPrice = Math.min(minPrice, sellingPrice);
        maxPrice = Math.max(maxPrice, sellingPrice);
      }
    });
    return { minPrice, maxPrice };
  };
  const getAttribute = () => {
    return selectedClassification.value && selectedSubClassification.value
      ? `${selectedClassification.value}, ${selectedSubClassification.value}`
      : selectedClassification.value || selectedSubClassification.value || '';
  };
  const onModalInteract = (value: boolean) => {
    setModalData((d) => {
      d.opened = value;
    });
  };
  const onAddToCart =
    () =>
    async (isBuyNow = false) => {
      try {
        const payload: CartPayload[] =
          product.classifications.length === 0
            ? [
                {
                  product_id: product.id,
                  quantity: quantity,
                },
              ]
            : [
                {
                  product_id: product.id,
                  quantity: quantity,
                  product_item_id:
                    selectedClassification.sub_classifications.length > 0
                      ? selectedSubClassification.product_item.id
                      : selectedClassification.product_item.id,
                },
              ];
        const res: CartUpdateResponse = await cartAPI.addToCart(
          payload,
          privateId.userId,
          currentWarehouse.id,
        );
        setError('');
        if (!isBuyNow) {
          if (!isMobile)
            toast(
              <CartNotification
                onClose={() => {
                  toast.dismiss();
                }}
                product={product}
                quantity={quantity}
                attribute={getAttribute()}
                navigate={() => {
                  navigate(PATH_CART);
                }}
              />,
            );
          else {
            setModalData((draft) => {
              draft.opened = true;
              //@ts-ignore
              draft.product = product;
            });
          }
        } else navigate(PATH_CART);
      } catch (error) {
        LogApp(error, 'kk');
      }
    };
  const increaseProductQuantity = () => {
    try {
      if (quantity < MAX_ITEM) setQuantity((prev) => prev + 1);
      else setQuantity(MAX_ITEM);
    } catch (error) {}
  };
  const decreaseProductQuantity = () => {
    try {
      if (quantity === 1) return;
      setQuantity((prev) => prev - 1);
    } catch (error) {}
  };
  const getProductBySubCategory = async (categoryId: string) => {
    try {
      const res: ProductFilterRoot = await homeAPI.getProductByFilter({
        category_ids: [categoryId],
        page: 0,
        warehouse_id: privateId.warehouseId,
      });
      setSameProducts(res.data.products);
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      setIsLoading(false);
    }
  };
  const onItemClick = (item: ProductsHotSelling) => {
    navigate(`${PATH_PRODUCT}/${item.code}`);
  };
  const onChangeQuantity = (quantity: number) => {
    if (quantity < MAX_ITEM) setQuantity(quantity);
    else setQuantity(MAX_ITEM);
  };
  const slideToIndex = (classification: Classification) => {
    const images = [
      ...product.images.map((item) => {
        return {
          image: item,
        };
      }),
      ...product.classifications.map((item) => {
        return {
          image: item.image,
        };
      }),
    ];
    const foundIndex = images
      .filter((item) => item.image !== null)
      .findIndex((item) => {
        return item.image?.id === classification.image?.id;
      });
    if (foundIndex !== -1) galleryRef.current?.slideToIndex(foundIndex);
  };
  useEffect(() => {
    if (id) getProduct();
  }, [id, currentWarehouse.id]);
  return (
    <ProductSection
      sameProducts={sameProducts}
      isLoading={isLoading}
      onBuyNow={() => {
        onAddToCart()(true);
      }}
      error={error}
      selectedClassification={selectedClassification}
      selectedSubClassification={selectedSubClassification}
      onSelectClassification={onSelectClassification}
      onSelectSubClassification={onSelectSubClassification}
      product={product}
      productPrice={getProductPrice()}
      increaseProductQuantity={increaseProductQuantity}
      decreaseProductQuantity={decreaseProductQuantity}
      quantity={quantity}
      onAddToCart={() => {
        onAddToCart()(false);
      }}
      onItemClick={onItemClick}
      onChangeQuantity={onChangeQuantity}
      modalData={modalData}
      getAttribute={getAttribute}
      onModalInteract={onModalInteract}
      isNotFound={isNotFound}
      onInteractAddProductModal={onInteractAddProductModal}
      modalOpened={modalOpened}
      galleryRef={galleryRef}
      getProductDeduction={getProductDeduction}
    />
  );
};
