import { BaseSyntheticEvent, memo } from 'react';
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import GoogleLogin, { GoogleLogout } from 'react-google-login';

import { AppModal, GoogleIcon, SharedButton, SharedImage, ShareInput, TelIcon } from '@components';
import { AUTH_THEME_COLOR, HEADER_HEIGHT, MAIN_THEME_DATA, SINGLE_HOST_ORIGIN } from '@configs';
import { opacityHex, themes, useTheme } from '@theme';
import styled from 'styled-components';
import { IMAGES } from '@assets';
import { IntroSectionModule } from '@modules';
import { logout, selectApp, useAppSelector } from '@redux';
import { LogApp } from '@utils';
import { Divider } from 'antd';
import { StyledLoginSection } from '../loginStyle';
import { LoginFailModal } from '../components/LoginFailModal';

interface IProps {
  redirectToForgot?: () => void;
  redirectToSignUp?: () => void;
  register: UseFormRegister<{ username: string; password: string }>;
  errors: Partial<FieldErrorsImpl<any>>;
  handleLogin: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  onClose: () => void;
  onConfirm: () => void;
  opened: boolean;
}

export const WebLoginForm = memo((props: IProps) => {
  const {
    errors,
    redirectToForgot,
    register,
    handleLogin,
    redirectToSignUp,
    onClose,
    onConfirm,
    opened,
  } = props;
  const { themeData } = useAppSelector(selectApp);
  const { theme } = useTheme();
  return (
    <StyledAuth className="login-page">
      <IntroSectionModule>
        <StyledLoginSection className="login__section">
          <h2 className="intro-x text-left xl:text-left heading-title">Đăng nhập</h2>
          <form onSubmit={handleLogin} className="login-form">
            <ShareInput
              placeholder="Số điện thoại"
              name="username"
              className="input"
              // type="number"
              errors={errors['username']?.message}
              register={register}
            />
            <ShareInput
              placeholder="Mật khẩu"
              name="password"
              className="input"
              type="password"
              errors={errors['password']?.message}
              register={register}
              haveShowPassIcon
            />
            <div className="actions">
              <SharedButton
                typeHtml="submit"
                text="Đăng nhập"
                backgroundColor={MAIN_THEME_DATA.mainColor}
                btnStyle="pad"
              />
              <span onClick={redirectToForgot} className="action-text forgot-text">
                Quên mật khẩu
              </span>
              <span className=" action-text">
                Bạn chưa có tài khoản?
                <span onClick={redirectToSignUp} className="register-now">
                  Đăng ký ngay
                </span>
              </span>
            </div>
          </form>
        </StyledLoginSection>
      </IntroSectionModule>
      <LoginFailModal opened={opened} onConfirm={onConfirm} onClose={onClose} />
    </StyledAuth>
  );
});

export const StyledAuth = styled.div<{ background?: string; $isHaveHighForm?: boolean }>`
  width: 100%;

  .auth-section {
    display: flex;
    justify-content: center;
  }
  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #000;
    font-family: Roboto-Medium;
    margin-bottom: 3.2rem;
  }
  .actions {
    display: flex;
    flex-direction: column;
    .action-text {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: ${MAIN_THEME_DATA.mainText};
      margin-top: 0.8rem;
    }
    .forgot-text {
      cursor: pointer;
    }
    .register-now {
      display: inline-block;
      margin-top: 2.4rem;
      cursor: pointer;
      color: ${MAIN_THEME_DATA.mainColor};
    }
  }
`;
