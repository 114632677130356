import { cartAPI } from '@api';
import { CartSection } from '@components';
import {
  DEFAULT_SUB_CLASSIFICATION_PARAMS,
  PATH_ORDER,
  PATH_ORDER_DETAIL,
  PATH_ORDER_SUCCESS,
} from '@configs';
import {
  CartItem,
  Classification,
  OrderDetailRoot,
  OrderPayload,
  Promotion,
  PromotionItem,
  PromotionType,
  SubClassification,
} from '@interfaces';
import { selectAuth, selectUser, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { LogApp, genOrderHash, readMoney, toVND } from '@utils';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  changeQuantity,
  clearCart,
  decreaseQuantity,
  deleteCartItem,
  getCart,
  increaseQuantity,
  selectCart,
  updateChildClassification,
  updateOrderHash,
  updateParentClassification,
} from 'src/redux/Slices/cart';
import { selectHome } from 'src/redux/Slices/home';
export const CartModule = () => {
  const dispatch = useAppDispatch();
  const cartData = useAppSelector(selectCart);
  const navigate = useNavigate();
  const { privateId } = useAppSelector(selectAuth);
  const { currentWarehouse } = useAppSelector(selectHome);
  const { userId } = privateId;
  const userState = useAppSelector(selectUser);
  const userInfo = userState?.userInfo;
  const [note, setNote] = useState('');
  const [noteError, setNoteError] = useState('');
  const getAttribute = (item: CartItem) => {
    return item.selectedClassifications.parent.value && item.selectedClassifications.child.value
      ? `${item.selectedClassifications.parent.value}, ${item.selectedClassifications.child.value}`
      : item.selectedClassifications.parent.value || item.selectedClassifications.child.value || '';
  };
  // const getDeductPercent = (item: CartItem) => {
  //   return item.selectedClassifications.parent.id !== '' &&
  //     item.selectedClassifications.child.id !== ''
  //     ? item.selectedClassifications.child.product_item.deduct_percent
  //     : item.selectedClassifications.parent.product_item.deduct_percent || item.deduct_percent;
  // };
  const getDeductPercent = (item: CartItem) => {
    return item.deduct_percent;
  };
  const calculateAmountPromotionDiscountPercent = (
    conditionAmount: number,
    promotions: PromotionItem[],
  ) => {
    let maxDiscountPercent = 0;
    for (const promotion of promotions) {
      if (conditionAmount >= promotion.condition_amount) {
        maxDiscountPercent = Math.max(maxDiscountPercent, promotion.discount_percent);
      }
    }
    return maxDiscountPercent;
  };
  const getDiscountPercent = (cartItem: CartItem) => {
    const prom = cartItem.promotions?.[0];
    const proItem = cartItem.promotions?.[0]?.promotion_items?.[0];
    if (proItem) {
      if (Number(prom?.type ?? -1) === PromotionType.AMOUNT) return proItem.discount_percent;
    }
    return prom?.discount_percent ?? 0;
  };
  const getDeductedMoney = () => {
    const itemDetails = cartData.cart.map((item) => ({
      deduct_percent: getDeductPercent(item),
      price: item.price,
      quantity: item.amount,
    }));
    const totalDeductedMoney = itemDetails.reduce(
      (acc, item) => acc + item.price * (item.deduct_percent / 100) * item.quantity,
      0,
    );
    return {
      number: toVND(totalDeductedMoney),
      text: readMoney(totalDeductedMoney.toString()),
    };
  };
  const getDiscountMoney = () => {
    const itemDetails = cartData.cart.map((item) => ({
      discount_percent: getDiscountPercent(item),
      price: item.price,
      quantity: item.amount,
      deducted_percent: item.deduct_percent,
    }));
    const totalDiscountMoney = itemDetails.reduce(
      (acc, item) =>
        acc +
        (item.price - (item.price * item.deducted_percent) / 100) *
          (item.discount_percent / 100) *
          item.quantity,
      0,
    );
    return {
      number: toVND(totalDiscountMoney),
      text: readMoney(totalDiscountMoney.toString()),
    };
  };
  const getPrice = () => {
    const itemDetails = cartData.cart.map((item) => ({
      price: item.price,
      quantity: item.amount,
    }));
    const deductedItems = cartData.cart.map((item) => ({
      deduct_percent: item.deduct_percent,
      price: item.price,
      quantity: item.amount,
    }));
    const promotionItemDetails = cartData.cart.map((item) => ({
      discount_percent: getDiscountPercent(item),
      price: item.price,
      quantity: item.amount,
      deduct_percent: item.deduct_percent,
    }));
    const totalDeductedMoney = deductedItems.reduce(
      (acc, item) => acc + item.price * (item.deduct_percent / 100) * item.quantity,
      0,
    );
    const totalDiscountMoney = promotionItemDetails.reduce(
      (acc, item) =>
        acc +
        (item.price - (item.price * item.deduct_percent) / 100) *
          (item.discount_percent / 100) *
          item.quantity,
      0,
    );
    const totalPrice = itemDetails.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const realPrice = parseInt((totalPrice - totalDeductedMoney - totalDiscountMoney).toString());
    return {
      totalPrice: toVND(totalPrice),
      number: toVND(realPrice),
      text: readMoney(realPrice.toString()),
    };
  };

  const increaseProductQuantity = async (item: CartItem, quantity: number) => {
    try {
      const payload = {
        quantity: item.amount + 1,
        product_id: item.product_id,
        product_item_id: item.product_item_id,
      };
      const res: CartItem[] = await cartAPI.updateCart([payload], userId, currentWarehouse.id);
      dispatch(increaseQuantity({ quantity, item }));
      dispatch(getCart(currentWarehouse.id));
    } catch (error) {
      LogApp(error, 'err');
    }
  };
  const decreaseProductQuantity = async (item: CartItem, quantity: number) => {
    try {
      const payload = {
        quantity: item.amount - 1,
        product_id: item.product_id,
        product_item_id: item.product_item_id,
      };
      const res: CartItem[] = await cartAPI.updateCart([payload], userId, currentWarehouse.id);
      dispatch(decreaseQuantity({ quantity, item }));
      dispatch(getCart(currentWarehouse.id));
    } catch (error) {
      LogApp(error, 'err');
    }
  };
  const changeProductQuantity = async (item: CartItem, quantity: number) => {
    try {
      const payload = {
        quantity,
        product_id: item.product_id,
        product_item_id: item.product_item_id,
      };
      const res: CartItem[] = await cartAPI.updateCart([payload], userId, currentWarehouse.id);
      dispatch(changeQuantity({ quantity, item }));
      dispatch(getCart(currentWarehouse.id));
    } catch (error) {
      LogApp(error, 'err');
    }
  };
  const handleProductQuantity = debounce((item: CartItem, quantity: number) => {
    changeProductQuantity(item, quantity);
  }, 500);
  const onDeleteCartItem = async (cartItem: CartItem) => {
    try {
      const payload = [
        cartItem?.product_item_id
          ? `product_item_${cartItem?.product_item_id}`
          : `product_${cartItem?.product_id}`,
      ];
      const res = await cartAPI.deleteCart(payload, userId, currentWarehouse.id);
      dispatch(deleteCartItem({ item: cartItem }));
    } catch (error) {}
  };
  const onUpdateParentClassification = async (
    cartItem: CartItem,
    classification: Classification,
  ) => {
    dispatch(
      updateParentClassification({
        cartItem,
        classification,
      }),
    );
    try {
      const payload = {
        quantity: cartItem.amount,
        product_id: cartItem.product_id,
        product_item_id: classification.product_item.id,
        product_item_id_old: cartItem.product_item_id,
      };
      const res: CartItem[] = await cartAPI.updateCart([payload], userId, currentWarehouse.id);
    } catch (error) {}
  };
  const onUpdateChildClassification = async (
    cartItem: CartItem,
    classification: SubClassification,
  ) => {
    if (!classification.id) return;
    dispatch(updateChildClassification({ cartItem, classification }));
    try {
      const payload = {
        quantity: cartItem.amount,
        product_id: cartItem.product_id,
        product_item_id: classification.product_item.id,
        product_item_id_old: cartItem.product_item_id,
      };
      const res: CartItem[] = await cartAPI.updateCart([payload], userId, currentWarehouse.id);
    } catch (error) {}
  };
  const onBack = () => {
    navigate(-1);
  };
  const navigateToOrder = async () => {
    try {
      const payload: OrderPayload = {
        customer_id: userId,
        promotion_ids: [],
        note,
        order_items: cartData.cart.map((item) => {
          if (item.product_item_id)
            return {
              product_item_id: item.product_item_id,
              quantity: item.amount,
              price: item.price,
              deduct_percent: getDeductPercent(item),
              reward: item.reward,
            };
          return {
            product_id: item.product_id,
            quantity: item.amount,
            price: item.price,
            deduct_percent: getDeductPercent(item),
            reward: item.reward,
          };
        }),
        warehouse_id: currentWarehouse.id,
      };
      dispatch(setLoading(true));
      const res: OrderDetailRoot = await cartAPI.createOrder(payload);
      const orderHash = genOrderHash(userId, res.data.id);
      dispatch(updateOrderHash(orderHash));
      navigate(PATH_ORDER_SUCCESS);
      dispatch(clearCart());
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setLoading(false));
      setNoteError('');
    }
  };
  const onNoteChange = (value: string) => {
    setNote(value);
  };
  const getUserAddress = () => {
    const baseAddress = `${userInfo.address.wards?.ward_name}, ${userInfo.address.districts?.district_name}, ${userInfo.address.provinces?.province_name}`;
    if (userInfo.address.detail) return `${userInfo.address.detail}, ${baseAddress}`;
    return baseAddress;
  };
  useEffect(() => {
    dispatch(getCart(currentWarehouse.id));
  }, [currentWarehouse.id]);
  LogApp(cartData.cart);
  return (
    <CartSection
      isLoading={cartData.loading}
      cart={cartData.cart}
      getAttribute={getAttribute}
      getPrice={getPrice}
      increaseProductQuantity={increaseProductQuantity}
      decreaseProductQuantity={decreaseProductQuantity}
      onUpdateParentClassification={onUpdateParentClassification}
      onUpdateChildClassification={onUpdateChildClassification}
      onDeleteCartItem={onDeleteCartItem}
      onBack={onBack}
      navigateToOrder={navigateToOrder}
      onChangeQuantity={() => {}}
      getDeductedMoney={getDeductedMoney}
      getDiscountMoney={getDiscountMoney}
      noteError={noteError}
      onNoteChange={onNoteChange}
      getDiscountPercent={getDiscountPercent}
      getUserAddress={getUserAddress}
      handleProductQuantity={handleProductQuantity}
    />
  );
};
