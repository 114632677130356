import { BaseSyntheticEvent, CSSProperties, memo } from 'react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';

import { MAIN_THEME_DATA } from '@configs';
import { useMediaQuery } from '@utils';
import { AppVerifyOTPForm } from './app/AppVerifyOTP';
import { WebVerifyOTPForm } from './web/WebVerifyOTP';
interface IProps {
  errors: Partial<FieldErrorsImpl<any>>;
  countdown?: boolean;
  register: UseFormRegister<FieldValues>;
  redirectToForgot?: () => void;
  handleVerifyOTP: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  handleResendEmail?: () => void;
  onEndResendOTPCountdown: () => void;
  forgotUsername?: string;
  onChangeOtp: (code: string) => void;
  otp: string;
}
const inputCtnStyle: CSSProperties = {
  justifyContent: 'space-between',
  paddingLeft: '2.7rem',
  paddingRight: '2.7rem',
};
const otpInputStyle: CSSProperties = {
  // width: 'calc((100% - 8rem )/6)',
  width: '4.8rem',
  height: '5.6rem',
  textAlign: 'center',
  margin: ' 0 auto',
  border: '1px solid  #888',
  borderRadius: '0.8rem',
  fontSize: '2.4rem',
  fontFamily: 'Roboto-Medium',
};
export const VerifyOTPForm = memo((props: IProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isMobile) return <AppVerifyOTPForm {...props} />;
  return <WebVerifyOTPForm {...props} />;
});

const StyledVerifyOTPForm = styled.section<{
  $themeColor?: string;
}>`
  p {
    margin-bottom: 0;
  }
  width: 57%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem;
  padding: 2.4rem 3.2rem;
  .resend-contain {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 3.6rem;
    .text {
      font-size: 1.7rem;
    }
    .resend-after {
      margin-right: 0.5rem;
    }
    .time-wrapper {
      display: flex;
      .time-block {
        margin-left: 0.5rem;
        display: flex;
        align-items: flex-end;
      }
      .resend-text {
        cursor: pointer;
      }
    }
  }
  .ant-statistic-content {
    font-family: Roboto-Medium;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
  .notification {
    margin-bottom: 3.6rem;
    p {
      font-size: 1.7rem;
      font-style: normal;
      line-height: 140%;
      text-align: center;
    }
    .phone {
      font-weight: 500;
      font-family: Roboto-Medium;
      margin-top: 1rem;
    }
  }
  input:focus {
    outline: 2px solid ${MAIN_THEME_DATA.mainColor};
  }
  .actions {
    margin-top: 3.2rem;
  }
  .heading {
    position: relative;
    display: flex;
    justify-content: center;
    .icon {
      position: absolute;
      left: 0;
      top: 2px;
      cursor: pointer;
    }
  }
`;
