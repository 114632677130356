import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { authAPI, userAPI } from '@api';
import { LoginForm } from '@components';
import { PATH_FORGOT_PASSWORD, PATH_SIGN_UP, PATH_UPDATE_PROFILE } from '@configs';
import { yupResolver } from '@hookform/resolvers/yup';
import { DecodedRoot, LoginPayload, LoginRoot, RootResponse, UserInfoRoot } from '@interfaces';
import {
  selectApp,
  setAccessToken,
  setLoading,
  setPrivateId,
  setRegisterInfo,
  setUserInfo,
  setVersion,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp } from '@utils';
import jwt_decode from 'jwt-decode';
import { useState } from 'react';
import { resetState, setCurrentWarehouse } from 'src/redux/Slices/home';

const schema = yup.object().shape({
  username: yup.string().required('Vui lòng nhập số điện thoại'),
  // .min(6, 'Phone number is invalid')
  // .max(13, 'Phone number is invalid'),
  password: yup.string().required('Vui lòng nhập mật khẩu'),
});

export const LoginModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [opened, setOpened] = useState(false);
  const { themeMode, merchantId } = useAppSelector(selectApp);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const onModalInteract = (value: boolean) => {
    setOpened(value);
  };
  const handleRedirectToForgot = () => {
    navigate(PATH_FORGOT_PASSWORD);
  };

  const handleRedirectToSignUp = () => {
    navigate(PATH_SIGN_UP);
  };
  const onUpdateProfile = (data: any) => {
    onModalInteract(true);
    dispatch(
      setRegisterInfo({
        ...data,
        province: { ...data?.address?.provinces, name: data?.address?.provinces?.province_name },
        district: { ...data?.address?.districts, name: data?.address?.districts?.district_name },
        ward: { ...data?.address?.wards, name: data?.address?.wards?.ward_name },
        seller: data?.seller,
        warehouse: data?.warehouse,
        seller_id: data?.seller?.id,
        image_id: data?.image?.id,
        image_url: data?.image?.url,
        customer_type_id: data?.customer_level?.id,
        customer_id: data?.id,
      }),
    );
  };
  const onConfirm = () => {
    onModalInteract(false);
    navigate(PATH_UPDATE_PROFILE);
  };
  const onClose = () => {
    onModalInteract(false);
    dispatch(setRegisterInfo(undefined));
  };
  const checkVersion = async () => {
    try {
      const response: RootResponse<string> = await authAPI.checkVersion();
      dispatch(setVersion(response.data));
      dispatch(resetState());
    } catch (error) {}
  };
  const handleLogin = handleSubmit(async (value) => {
    try {
      const { username, password } = value;
      const payload: LoginPayload = {
        username,
        password,
      };
      dispatch(setLoading(true));
      const res: LoginRoot = await authAPI.login(payload);
      checkVersion();
      const decodedToken = jwt_decode(res.data.access_token) as DecodedRoot;
      LogApp(decodedToken, 'decoded');
      dispatch(setAccessToken(res.data.access_token));
      const userId = decodedToken.claims.user_id;
      if (userId) {
        dispatch(
          setPrivateId({
            userId,
            warehouseId: decodedToken.claims.warehouse_id,
          }),
        );
        const res: UserInfoRoot = await userAPI.getUserInfo(userId);
        dispatch(setUserInfo(res.data));
        dispatch(
          setCurrentWarehouse({
            id: res.data.warehouse.id,
            name: res.data.warehouse.name,
          }),
        );
      }
    } catch (err: any) {
      if (err.status === 409) {
        const data = err.data.data;
        onUpdateProfile(data);
      } else if (err.data) setError('password', { type: 'custom', message: err.data.detail });
    } finally {
      dispatch(setLoading(false));
    }
  });

  return (
    <LoginForm
      register={register}
      redirectToForgot={handleRedirectToForgot}
      redirectToSignUp={handleRedirectToSignUp}
      errors={errors}
      handleLogin={handleLogin}
      onConfirm={onConfirm}
      onClose={onClose}
      opened={opened}
    />
  );
};
