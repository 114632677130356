import { orderAPI } from '@api';
import { OrderSection } from '@components';
import {
  DEFAULT_ORDER_DETAIL,
  MAX_ITEM_PER_PAGE,
  ORDER_STATUS,
  ORDER_STATUS_NUMBER,
  PATH_ORDER,
} from '@configs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  GetOrderParams,
  Order,
  OrderData,
  OrderItem,
  OrderResponse,
  OrderRoot,
  OrderStatusResponse,
  RatePayload,
  RootResponse,
} from '@interfaces';
import { selectAuth, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { useImmer } from 'use-immer';
import * as yup from 'yup';
//@ts-ignore
import { NotificationContainer, NotificationManager } from 'react-notifications';
const schema = yup.object().shape({
  detail: yup.string().required('Vui lòng nhập đánh giá'),
});
export const OrderModule = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      detail: '',
    },
  });
  const navigate = useNavigate();
  const { privateId } = useAppSelector(selectAuth);
  const { userId } = privateId;
  const [isLoading, setIsLoading] = useState(true);
  const [orderCount, setOrderCount] = useImmer({
    [String(ORDER_STATUS_NUMBER.ALL)]: 0,
    [String(ORDER_STATUS_NUMBER.APPROVED)]: 0,
    [String(ORDER_STATUS_NUMBER.CANCEL)]: 0,
    [String(ORDER_STATUS_NUMBER.DEBT)]: 0,
    [String(ORDER_STATUS_NUMBER.DELIVERING)]: 0,
    [String(ORDER_STATUS_NUMBER.RECEIVED)]: 0,
    [String(ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT)]: 0,
    [String(ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE)]: 0,
  });
  const [modalData, setModalData] = useImmer<{ opened: boolean; orderItem: Order }>({
    opened: false,
    orderItem: DEFAULT_ORDER_DETAIL,
  });
  const [rating, setRating] = useState(5);
  const [orders, setOrders] = useImmer<OrderData>({
    count: 0,
    limit: 0,
    data: [],
  });
  const [payload, setPayload] = useImmer<GetOrderParams>({
    status: null,
    from_date: null,
    to_date: null,
    page: 0,
    customer_id: userId,
  });
  const getOrders = async () => {
    try {
      setIsLoading(true);
      const res: OrderRoot = await orderAPI.getOrders(payload);
      setOrders({
        count: MAX_ITEM_PER_PAGE,
        data: res.data.orders,
        limit: res.data.count_order,
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const getOrderCounts = async () => {
    const countRes: RootResponse<OrderStatusResponse[]> = await orderAPI.getOrderCounts(payload);
    setOrderCount((d) => {
      const data = countRes.data;
      const counts: any = {};
      if (data.length === 0) {
        Object.values(ORDER_STATUS_NUMBER).forEach((item) => {
          d[item as number] = 0;
        });
      }
      data.forEach((item) => {
        d[item.status] = item.count;
      });
      d['null'] = data.reduce((acc, cur) => {
        return acc + cur.count;
      }, 0);
      d = counts;
    });
  };
  const onChangeStatus = (status: string) => {
    if (status === 'null')
      setPayload((draft) => {
        draft.status = null;
        draft.debt = null;
        draft.page = 0;
      });
    else if (status === ORDER_STATUS_NUMBER.DEBT.toString())
      setPayload((draft) => {
        draft.debt = true;
        draft.page = 0;
      });
    else
      setPayload((draft) => {
        draft.status = Number(status);
        draft.debt = null;
        draft.page = 0;
      });
  };
  const onPageChange = (page: number) => {
    setPayload((draft) => {
      draft.page = page;
    });
  };
  const navigateToOrderDetail = (id: string) => {
    navigate(`${PATH_ORDER}/${id}`);
  };
  const onDateChange = (value: string[]) => {
    setPayload((draft) => {
      draft.from_date = value[0] === '' ? null : value[0];
      draft.to_date = value[1] === '' ? null : value[1];
    });
  };
  const onModalInteract = (value: boolean) => {
    setModalData((draft) => {
      draft.opened = value;
    });
  };
  const onReview = (order: Order) => {
    setModalData((draft) => {
      draft.opened = true;
      //@ts-ignore
      draft.orderItem = order;
    });
  };
  const onRateChange = (star: number) => {
    setRating(star);
  };
  const onClose = () => {
    setModalData((draft) => {
      draft.opened = false;
    });
    setRating(5);
    reset({
      detail: '',
    });
  };
  const submitReview = handleSubmit(async (value) => {
    try {
      dispatch(setLoading(true));
      const { detail } = value;
      const payload: RatePayload = {
        content: detail,
        rate: rating,
        order_id: modalData.orderItem.id,
        image_id: null,
        parent_id: null,
      };
      const res = await orderAPI.rateOrder(payload);
      NotificationManager.success('Thông báo', 'Thành công');
      onClose();
    } catch (err: any) {
    } finally {
      dispatch(setLoading(false));
    }
  });
  const onUpdateOrder = async (id: string) => {
    try {
      dispatch(setLoading(true));
      const res = await orderAPI.updateOrder(id, ORDER_STATUS_NUMBER.RECEIVED);
      await getOrders();
    } catch (err: any) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    getOrders();
    getOrderCounts();
  }, [payload]);
  return (
    <OrderSection
      payload={payload}
      isLoading={isLoading}
      orders={orders}
      onChangeStatus={onChangeStatus}
      navigateToOrderDetail={navigateToOrderDetail}
      onPageChange={onPageChange}
      onDateChange={onDateChange}
      onReview={onReview}
      modalData={modalData}
      onRateChange={onRateChange}
      rating={rating}
      register={register}
      errors={errors}
      onClose={onClose}
      submitReview={submitReview}
      onUpdateOrder={onUpdateOrder}
      orderCount={orderCount}
    />
  );
};
