import { BaseSyntheticEvent, memo } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import { MAIN_THEME_DATA } from '@configs';
import { useMediaQuery } from '@utils';
import styled from 'styled-components';
import { AppLoginForm } from './app/AppLogin';
import { WebLoginForm } from './web/WebLogin';

interface IProps {
  redirectToForgot?: () => void;
  redirectToSignUp?: () => void;
  register: UseFormRegister<{ username: string; password: string }>;
  errors: Partial<FieldErrorsImpl<any>>;
  handleLogin: (e?: BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
  onClose: () => void;
  onConfirm: () => void;
  opened: boolean;
}

export const LoginForm = memo((props: IProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isMobile) return <AppLoginForm {...props} />;
  return <WebLoginForm {...props} />;
});

export const StyledAuth = styled.div<{ background?: string; $isHaveHighForm?: boolean }>`
  width: 100%;
  .auth-section {
    display: flex;
    justify-content: center;
  }
  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #000;
    font-family: Roboto-Medium;
    margin-bottom: 3.2rem;
  }
  .actions {
    display: flex;
    flex-direction: column;
    .action-text {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: ${MAIN_THEME_DATA.mainText};
      margin-top: 0.8rem;
    }
    .forgot-text {
      cursor: pointer;
    }
    .register-now {
      display: inline-block;
      margin-top: 2.4rem;
      cursor: pointer;
      color: ${MAIN_THEME_DATA.mainColor};
    }
  }
`;
export const StyledMobileAuth = styled.div<{ background?: string; $isHaveHighForm?: boolean }>`
  width: 100%;
  .app-modal {
    .content {
      margin: 0 1.6rem;
    }
  }
  .auth-section {
    display: flex;
    justify-content: center;
  }
  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #000;
    font-family: Roboto-Medium;
    margin-bottom: 3.2rem;
  }
  .actions {
    display: flex;
    flex-direction: column;
    .action-text {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: ${MAIN_THEME_DATA.mainText};
      margin-top: 0.8rem;
    }
    .forgot-text {
      cursor: pointer;
    }
    .register-now {
      display: inline-block;
      margin-top: 2.4rem;
      cursor: pointer;
      color: ${MAIN_THEME_DATA.mainColor};
    }
  }
`;
