import { IMAGES } from '@assets';
import { MAIN_THEME_DATA } from '@configs';
import {
  Classification,
  ProductDetailResponse,
  ProductFilter,
  ProductsHotSelling,
  SubClassification,
} from '@interfaces';
import parse from 'html-react-parser';
import ImageGallery from 'react-image-gallery';
import { GiftIcon } from 'src/components/Icon';
import { styled } from 'styled-components';
import { HomeList } from '../../home/components/HomeList';
import { LeftArrow, RightArrow } from '../../home/components/web';
import { SharedButton, SharedImage } from '../../shared';
import { AddProductModal } from '../components/AddProductModal';
import { Description } from '../components/Description';
import { LoadingView } from '../components/LoadingView';
import { Policy } from '../components/Policy';
import { Rating } from '../components/Rating';
import { Size } from '../components/Size';
import { LogApp } from '@utils';
import { QuantityControl } from '../components/QuantityControl';
const MIN_QUANTITY = 20;
const MAX_QUANTITY = 100;
interface ProductSectionProps {
  error: string;
  product: ProductDetailResponse;
  onSelectClassification: (classification: Classification) => void;
  onSelectSubClassification: (classification: SubClassification) => void;
  selectedClassification: Classification;
  selectedSubClassification: SubClassification;
  productPrice: string;
  quantity: number;
  increaseProductQuantity: () => void;
  decreaseProductQuantity: () => void;
  onAddToCart: () => void;
  onBuyNow: () => void;
  isLoading: boolean;
  sameProducts: ProductFilter[];
  onItemClick: (item: ProductsHotSelling) => void;
  onChangeQuantity: (quantity: number) => void;
  isNotFound: boolean;
  onInteractAddProductModal: (value: boolean) => void;
  modalOpened: boolean;
  galleryRef: any;
  getProductDeduction: () => number;
}
export const WebProductSection = (props: ProductSectionProps) => {
  const {
    product,
    selectedClassification,
    onSelectClassification,
    onSelectSubClassification,
    selectedSubClassification,
    productPrice,
    increaseProductQuantity,
    decreaseProductQuantity,
    quantity,
    onAddToCart,
    error,
    onBuyNow,
    isLoading,
    sameProducts,
    onItemClick,
    onChangeQuantity,
    isNotFound,
    onInteractAddProductModal,
    modalOpened,
    galleryRef,
    getProductDeduction,
  } = props;
  const hasSubClassification = product.classifications.every(
    (item) => item.sub_classifications.length > 0,
  );
  const getQuantity = (quantity: number) => {
    if (quantity <= 20) return MIN_QUANTITY;
    if (quantity >= 100) return MAX_QUANTITY;
    return quantity;
  };
  const images =
    product.classifications.length > 0
      ? [
          ...product.images.map((item) => {
            return {
              image: {
                url: item.url,
              },
            };
          }),
          ...product.classifications,
        ]
          .map((item) => {
            return {
              original: item.image?.url ?? '',
              thumbnail: item.image?.url ?? '',
            };
          })
          .filter((item) => item.original)
      : product.images.map((item) => {
          return {
            original: item.url,
            thumbnail: item.url,
          };
        });
  return (
    <StyledProductSection error={error}>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          {isNotFound ? (
            <div className="flex items-center flex-col">
              <SharedImage containerClassName="not-found-img" src={IMAGES.searchNotFound} />
              <span className="not-found-text">
                Không tìm thấy sản phẩm này. Vui lòng xem sản phẩm khác hoặc chọn kho khác
              </span>
            </div>
          ) : (
            <>
              <StyledBreadCrumb>
                <div className="bread-crumb-wrapper">
                  <div className="bread-crumb-item">
                    <span>{product.categories?.[0]?.name} /&nbsp;</span>
                    <span>{product.categories?.[0]?.sub_categories?.[0]?.name} /&nbsp;</span>
                    <span className={`red`}>{product.name}</span>
                  </div>
                </div>
              </StyledBreadCrumb>
              <div className="detail-wrapper">
                <h3>{product.name}</h3>
                <div className="content-block">
                  <ImageGallery
                    ref={galleryRef}
                    renderLeftNav={(onClick, disabled) => {
                      return <LeftArrow onClick={onClick} className="nav-btn left-nav" />;
                    }}
                    renderRightNav={(onClick, disabled) => {
                      return <RightArrow onClick={onClick} className="nav-btn right-nav" />;
                    }}
                    renderFullscreenButton={() => {
                      return null;
                    }}
                    renderPlayPauseButton={() => {
                      return null;
                    }}
                    items={images}
                  />
                  <div className="product-info-block">
                    <div>
                      <div className="m-left-4">
                        <span className="price-text">{productPrice}</span>
                        {getProductDeduction() > 0 && (
                          <span className="deduct-percent">
                            Chiết khấu: {getProductDeduction()}%
                          </span>
                        )}
                        <div className="status-block">
                          <div>
                            <span className="text">Tình trạng:</span>
                            <span className="text content">Còn hàng</span>
                          </div>
                          <div className="divider" />
                          {!product.classifications.length && (
                            <>
                              <div>
                                <span className="text">Số lượng:</span>
                                <span className="text content red">
                                  {getQuantity(product.quantity)}
                                </span>
                              </div>
                              <div className="divider" />
                            </>
                          )}
                          <div>
                            <span className="text">Đơn vị:</span>
                            <span className="text content">{product.unit}</span>
                          </div>
                          <div className="divider" />
                          <div>
                            <span className="text">Loại hàng:</span>
                            <span className="text content">{product.type}</span>
                          </div>
                          <div className="divider" />
                          <div>
                            <span className="text">Lượt xem:</span>
                            <span className="text content">{product.view}</span>
                          </div>
                          <div className="divider" />
                          <div className="rating-block">
                            <span className="text">Đánh giá:</span>
                            <span>
                              <Rating numStar={5} />
                            </span>
                          </div>
                        </div>
                        {product?.promotions?.length > 0 && (
                          <div className="promotion">
                            <div className="header">
                              <span>Khuyến mãi đặc biệt</span>
                            </div>
                            <div className="content">
                              {product.promotions.map((item) => {
                                return (
                                  <div id={item.id}>
                                    <div className="promotion-item">
                                      <GiftIcon />
                                      <span className="pro-text">{item.name}</span>
                                    </div>
                                    <div className="ml-4">
                                      {parse(item?.description || '<div/>')}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="attribute-block">
                        {product?.classifications?.length > 0 && (
                          <div className="size-block">
                            <span className="size-text text">
                              {product?.classifications?.[0]?.attribute_name}
                            </span>
                            <div className="size-content">
                              {product.classifications.map((item) => {
                                return (
                                  <Size
                                    key={item.id}
                                    isSelected={item.id === selectedClassification.id}
                                    onClick={() => {
                                      onSelectClassification(item);
                                    }}
                                    hasQuantity={!item.sub_classifications.length}
                                    title={item.value}
                                    quantity={getQuantity(item?.product_item?.quantity)}
                                    percent={item.product_item?.deduct_percent}
                                    titleClassName={'text'}
                                    quantityClassName={'text content'}
                                  />
                                );
                              })}
                            </div>
                            <span className="size-text text">
                              {selectedClassification?.sub_classifications?.[0]?.attribute_name}
                            </span>
                            <div className="size-content">
                              {selectedClassification?.sub_classifications?.map((item) => {
                                return (
                                  <Size
                                    key={item.id}
                                    isSelected={selectedSubClassification.id === item.id}
                                    onClick={() => {}}
                                    hasQuantity
                                    title={`${item?.value}`}
                                    quantity={getQuantity(item?.product_item?.quantity)}
                                    percent={item.product_item?.deduct_percent}
                                    titleClassName={'text'}
                                    quantityClassName={'text content'}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        )}
                        {!product.classifications.length && (
                          <div className="quantity-block">
                            <QuantityControl
                              onDecrease={decreaseProductQuantity}
                              onIncrease={increaseProductQuantity}
                              quantity={quantity}
                              onChangeQuantity={onChangeQuantity}
                            />
                            {error && <span className="error-text">{error}</span>}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="btn-block m-left-4">
                      <SharedButton
                        onClick={() => {
                          if (product.classifications.length) onInteractAddProductModal(true);
                          else onAddToCart();
                        }}
                        textClassName="text-btn"
                        className="mr"
                        btnStyle="line"
                        textColor={MAIN_THEME_DATA.mainColor}
                        text="Thêm vào giỏ hàng"
                      />
                      <SharedButton
                        onClick={() => {
                          if (product.classifications.length) onInteractAddProductModal(true);
                          else onBuyNow();
                        }}
                        text="Mua ngay"
                      />
                    </div>
                  </div>
                </div>
                <div className="description-block">
                  <Description title={'Chi tiết sản phẩm'}>
                    {
                      <>
                        {product.attributes.length === 0 ? (
                          <p className="text-center">Không có mô tả cho sản phẩm này</p>
                        ) : (
                          <>
                            {product.attributes.map((item) => {
                              return (
                                <div className="attr-item">
                                  <span className="attr-title">{item.attribute_name}:</span>
                                  <span>{item.value}</span>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </>
                    }
                  </Description>
                  {product?.description && (
                    <Description className="mb" title={'Mô tả sản phẩm'}>
                      {parse(product?.description || '<div/>')}
                    </Description>
                  )}
                  <Description title={'Chính sách cửa hàng'}>
                    <Policy />
                  </Description>
                </div>
                <AddProductModal
                  onClose={() => {
                    onInteractAddProductModal(false);
                  }}
                  modalOpened={modalOpened}
                  productItems={(hasSubClassification
                    ? selectedClassification.sub_classifications
                    : product.classifications
                  ).map((item) => {
                    return {
                      product_id: product.id,
                      product_item_id: item.product_item.id,
                      quantity: 0,
                      price:
                        item.product_item.selling_price *
                        (1 - item.product_item.deduct_percent / 100),
                    };
                  })}
                  product={product}
                  selectedClassification={selectedClassification}
                  classifications={product.classifications}
                />
              </div>
            </>
          )}
        </>
      )}
      {!isNotFound && (
        <HomeList
          hasPrice
          isLoading={isLoading}
          footerClassNameItem="product-footer-item"
          title="Sản phẩm tương tự"
          data={sameProducts}
          onItemClick={onItemClick}
        />
      )}
    </StyledProductSection>
  );
};
const StyledProductSection = styled.div<{ error: string }>`
  .modal {
    .content {
      padding: 0;
      width: 60rem;
    }
  }
  .not-found-img {
    width: 30rem;
    aspect-ratio: 759/735;
  }
  .not-found-text {
    text-align: center;
    font-size: 2.4rem;
    font-family: Roboto-Medium;
    margin-top: 1.6rem;
  }
  .promotion-item {
    .promotion-title {
      font-size: 2rem;
      font-family: Roboto-Medium;
    }
    .promotion-info {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      .promotion-text {
        font-size: 1.7rem;
        font-family: Roboto-Medium;
        margin-left: 0.5rem;
      }
    }
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
  }
  /* padding: 0 12rem; */
  .text-btn {
    font-size: 2.4rem;
  }
  .quantity-block {
    .title {
      // Số lượng
      color: ${MAIN_THEME_DATA.mainText};
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 19.6px;
      font-family: Roboto-Medium;
    }
    margin-top: 2.4rem;
    .error-text {
      color: ${MAIN_THEME_DATA.mainColor};
      margin-top: 1rem;
      display: inline-block;
    }
  }
  .detail-wrapper {
    padding: 0 12rem;
    margin-top: 4rem;
  }
  .image-gallery {
    width: calc((536 / 1200) * 100%);
    /* aspect-ratio: 0.807; */
  }
  .image-gallery-slide-wrapper {
  }
  .image-gallery-swipe {
  }
  .image-gallery-image {
    width: 100%;
    aspect-ratio: 1;
    /* object-fit: fill; */
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .image-gallery-content {
    background-color: white;
    border-radius: 12px;
  }
  .image-gallery-thumbnails {
    padding: 1.6rem 2.4rem;
  }
  .image-gallery-thumbnail {
    width: 9.6rem;
    aspect-ratio: 1;
    border-radius: 12px;
    margin-right: 1rem;
  }
  .image-gallery-thumbnail-inner {
    width: 100%;
    height: 100%;
  }
  .image-gallery-thumbnail-image {
    width: 100%;
    aspect-ratio: 1;
    /* object-fit: fill; */
    border-radius: 9px;
  }
  .image-gallery-thumbnail.active {
    border-color: ${MAIN_THEME_DATA.mainColor};
  }
  .nav-btn {
    color: #fff;
    background-color: white;
    outline: none;
    position: absolute;
    z-index: 4;
    top: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    transform: translateY(-50%);
  }
  .left-nav {
    left: 2.4rem;
  }
  .right-nav {
    right: 2.4rem;
  }
  .content-block {
    display: flex;
    flex-direction: row;
    margin-bottom: 4rem;
    /* width: 46%; */
  }
  h3 {
    // Bóng chuyền Gerll Star BS-700 Super X - Chính hãng
    color: ${MAIN_THEME_DATA.mainText};
    font-size: 2.4rem;
    font-family: Roboto-Medium;
    font-weight: 500;
    line-height: 33.6px;
    margin-bottom: 2.4rem;
  }
  .m-left-4 {
    margin-left: 4rem;
  }
  .product-info-block {
    /* margin-left: 4rem; */
    .red {
      color: ${MAIN_THEME_DATA.mainColor} !important;
    }
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .promotion {
      width: 60%;
      margin-top: 1.6rem;
      .header {
        background-color: ${MAIN_THEME_DATA.mainColor};
        border-top-right-radius: 1.6rem;
        border-top-left-radius: 1.6rem;
        padding: 1rem 0 1rem 1rem;
        span {
          color: #fff;
          font-size: 1.4rem;
        }
      }
      .content {
        border: 1px solid ${MAIN_THEME_DATA.mainColor};
        border-bottom-left-radius: 1.6rem;
        border-bottom-right-radius: 1.6rem;
        padding: 0.8rem 1.6rem;
        p {
          margin-bottom: 0;
        }
        .promotion-item {
          display: flex;
          .pro-text {
            margin-left: 1rem;
          }
        }
      }
    }
    .deduct-percent {
      font-size: 1.4rem;
      color: ${MAIN_THEME_DATA.mainColor};
      font-style: italic;
    }
    .price-text {
      color: ${MAIN_THEME_DATA.mainColor};
      font-size: 3.2rem;
      font-family: Roboto-Medium;
      font-weight: 500;
      line-height: 44.8px;
      word-wrap: break-word;
      margin-right: 1rem;
    }
    .org-price-text {
      color: #888888;
      font-size: 2.4rem;
      font-family: Roboto-Medium;
      font-weight: 400;
      text-decoration: line-through;
      line-height: 33.6px;
      word-wrap: break-word;
    }
    .text {
      color: #888888;
      font-size: 1.4rem;
      font-family: Roboto-Medium;
      font-weight: 400;
      line-height: 19.6px;
      margin-right: 0.5rem;
    }
    .content {
      /* color: ${MAIN_THEME_DATA.mainColor}; */
    }
    .status-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      .rating-block {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .divider {
        height: 1.6rem;
        width: 1px;
        background-color: #888888;
        margin: 0 0.8rem;
      }
    }
    .attribute-block {
      margin-top: 1.2rem;
      background-color: ${(p) => (p.error ? '#FFE8E5' : `${MAIN_THEME_DATA.bgColor}`)};
      padding: 1.2rem 0 1.2rem 1.2rem;
      margin-left: 2.8rem;
    }
    .size-block {
      .size-text {
        color: ${MAIN_THEME_DATA.mainText};
      }
      .size-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 1.2rem;
      }
    }
    .btn-block {
      display: flex;
      width: 100%;
      .mr {
        margin-right: 2.4rem;
      }
    }

    .description-block {
    }
  }
  .react-horizontal-scrolling-menu--item {
    width: 22.8rem;
    margin-right: 1.5rem;
    flex-shrink: 0;
  }
  .image-gallery-thumbnail:focus {
    border-color: ${MAIN_THEME_DATA.mainColor};
  }
  .image-gallery-thumbnail:hover {
    border-color: ${MAIN_THEME_DATA.mainColor};
  }
`;
const StyledBreadCrumb = styled.div`
  padding: 1rem 0;
  background-color: #ffd0cd;
  padding-left: 12rem;
  .bread-crumb-wrapper {
    display: flex;
    .red {
      color: ${MAIN_THEME_DATA.mainColor} !important;
    }
    .slash {
      margin: 0 0.8rem;
    }
    .bread-crumb-item {
      display: flex;
      span {
        color: ${MAIN_THEME_DATA.mainText};
        font-size: 1.4rem;
        font-family: Roboto-Regular;
        font-weight: 400;
        line-height: 19.6px;
        display: inline-block;
      }
    }
  }
`;
