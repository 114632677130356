import { ProductSearchResultSection } from '@components';
import { PATH_PRODUCT } from '@configs';
import { useAppDispatch, useAppSelector } from '@redux';
import { LogApp } from '@utils';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getProductsByKeyword,
  selectHome,
  setCurrentProductSearchPage,
} from 'src/redux/Slices/home';

export const ProductSearchResultModule = () => {
  const { currentWarehouse, searchedProduct } = useAppSelector(selectHome);
  const warehouseId = currentWarehouse?.id;
  const dispatch = useAppDispatch();
  const { currentKeyword } = useAppSelector(selectHome);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keyword = searchParams.get('key');
  const navigateToProductDetail = (id: string) => {
    navigate(PATH_PRODUCT + `/${id}`);
  };
  useEffect(() => {
    if (!keyword) return;
    dispatch(
      getProductsByKeyword({
        page: searchedProduct.page,
        keyword: currentKeyword,
        warehouse_id: warehouseId,
        size: 25,
      }),
    );
  }, [searchedProduct.page]);
  const onPageChange = (page: number) => {
    dispatch(setCurrentProductSearchPage(page));
  };
  return (
    <ProductSearchResultSection
      keyword={currentKeyword || ''}
      isLoading={searchedProduct.loading}
      products={searchedProduct.data}
      navigateToProductDetail={navigateToProductDetail}
      onPageChange={onPageChange}
      payload={{ page: searchedProduct.page }}
    />
  );
};
