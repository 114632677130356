import { PATH_HOME } from '@configs';
import { selectApp, selectAuth, useAppSelector } from '@redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const AuthRoute = () => {
  const { accessToken } = useAppSelector(selectAuth);
  const { merchantId } = useAppSelector(selectApp);
  const { pathname } = useLocation();

  // if (process.env.NODE_ENV === 'development') {
  //   return <Outlet />;
  // }

  return accessToken ? <Navigate to={PATH_HOME} /> : <Outlet />;
};
