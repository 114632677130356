import { SharedImage } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import { OrderItem } from '@interfaces';
import { LogApp, toVND } from '@utils';
import { styled } from 'styled-components';

interface ProductItemListProps {
  orderList: OrderItem[];
  isMobile?: boolean;
  getDiscountPercent?: (index: number) => number;
}
const WebProductItem = ({
  item,
  attribute,
  isLastItem,
  getDiscountPercent,
  index,
}: {
  item: OrderItem;
  attribute: string;
  isLastItem: boolean;
  getDiscountPercent?: (index: number) => number;
  index: number;
}) => {
  const promotion = item.promotions?.[0]?.promotion_items?.[0];
  const deductedPrice = (item.deduct_percent / 100) * item.price;
  const discountedPrice = ((promotion?.discount_percent || 0) / 100) * (item.price - deductedPrice);
  const finalPrice = (item.price - deductedPrice - discountedPrice) * item.amount;
  return (
    <div className={`order-item ${isLastItem ? 'no-border no-mb' : ''}`}>
      <div className="left-order-item">
        <SharedImage containerClassName="img-ctn" src={item.image?.url} />
        <div className="info-block">
          <div className="info-head">
            <span className="product-name med-text">{item.name}</span>
            <span>Phân loại: {attribute}</span>
            <span>Số lượng: {item.amount}</span>
            <span>
              Đơn giá: <span className="red-text">{toVND(item.price)}</span>
            </span>
            {item.deduct_percent > 0 && (
              <span>
                Chiết khấu:
                <span className="deduct-percent"> {item.deduct_percent || 0}%</span>
              </span>
            )}
            {promotion && (
              <span>
                {' '}
                Khuyến mãi:{' '}
                <span className="deduct-percent">
                  {item.promotions?.[0]?.code} - {promotion.discount_percent || 0}%
                </span>
              </span>
            )}
            <span>
              Giá cuối cùng (bao gồm chiết khấu, khuyến mãi):{' '}
              <span className="red-text">{toVND(item.price_deducted)}</span>
            </span>
          </div>
        </div>
      </div>
      <div>
        <span className="price red-text">{toVND(item.price_deducted * item.amount)}</span>
      </div>
    </div>
  );
};
const AppProductItem = ({ item, attribute }: { item: OrderItem; attribute: string }) => {
  const promotion = item.promotions?.[0]?.promotion_items?.[0];
  const deductedPrice = (item.deduct_percent / 100) * item.price;
  const discountedPrice = ((promotion?.discount_percent || 0) / 100) * (item.price - deductedPrice);
  const finalPrice = (item.price - deductedPrice - discountedPrice) * item.amount;
  return (
    <div className={`order-item`}>
      <div className="left-order-item">
        <SharedImage containerClassName="img-ctn" src={item.image?.url} />
        <div className="info-block">
          <div className="info-head">
            <div className="flex justify-between">
              <span className="product-name med-text">{item.name}</span>
              <span className="price">{toVND(item.price_deducted * item.amount)}</span>
            </div>
            <span>
              Phân loại: <span className="red-text">{attribute}</span>
            </span>
            <span>
              Đơn giá: <span className="price text-end red-text">{toVND(item.price)}</span>
            </span>
            {item.deduct_percent > 0 && (
              <span>
                Chiết khấu:
                <span className="deduct-percent">{item.deduct_percent || 0}%</span>
              </span>
            )}

            {promotion && (
              <div className="flex justify-between">
                <span className="deduct-percent">
                  Khuyến mãi: {promotion?.discount_percent || 0}%
                </span>
              </div>
            )}
            <span>
              Giá cuối cùng (bao gồm chiết khấu, khuyến mãi):{' '}
              <span className="red-text">{toVND(item.price_deducted)}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export const ProductItemList = (props: ProductItemListProps) => {
  const { orderList, isMobile, getDiscountPercent } = props;
  const getAttribute = (item: OrderItem) => {
    if (item.classification_name && item.sub_classification_name)
      return `${item.classification_name + ','} ${item.sub_classification_name}`;
    return item.classification_name || item.sub_classification_name || 'Không có';
  };
  return (
    <StyledOrderItemList>
      {orderList.map((item, index) => {
        if (isMobile) return <AppProductItem item={item} attribute={getAttribute(item)} />;
        return (
          <WebProductItem
            index={index}
            item={item}
            attribute={getAttribute(item)}
            isLastItem={index === orderList.length - 1}
            getDiscountPercent={getDiscountPercent}
          />
        );
      })}
    </StyledOrderItemList>
  );
};
const StyledOrderItemList = styled.div`
  width: 100%;
  .no-border {
    border-bottom: 0 !important;
  }
  .no-mb {
    margin-bottom: 0 !important;
  }
  .red-text {
    color: ${MAIN_THEME_DATA.mainColor};
  }
  .order-item {
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid #d9d9d9;
    .left-order-item {
      display: flex;
      flex-direction: row;
      width: 100%;
      .img-ctn {
        width: 8rem;
        aspect-ratio: 1;
        img {
          border-radius: 8px;
        }
      }
      .info-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 1.6rem;
        width: 100%;
        .info-head {
          display: flex;
          flex-direction: column;
          .deduct-percent {
            font-size: 1.4rem;
            color: ${MAIN_THEME_DATA.mainColor};
            font-style: italic;
          }
        }
      }
    }
    .discount-price {
      font-size: 1.7rem;
      @media (max-width: 768px) {
        font-size: 1.4rem;
      }
      color: ${MAIN_THEME_DATA.mainColor};
    }
    .origin-price {
      text-decoration: line-through;
      font-size: 1.4rem;
      text-align: right;
      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
    .price {
      font-size: 1.7rem;
      @media (max-width: 768px) {
        font-size: 1.4rem;
      }
    }
    span {
      display: inline-block;
    }
    .product-name {
      @media (max-width: 768px) {
        font-size: 1.4rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 140%;
      margin-bottom: 0.4rem;
    }
  }
`;
