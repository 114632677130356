import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { authAPI } from '@api';
import { SignUpForm } from '@components';
import {
  DEFAULT_ADDRESS_ITEM,
  DEFAULT_SELLER_ITEM,
  DEFAULT_WAREHOUSE_ITEM,
  PATH_LOGIN,
  PATH_RESET_PASSWORD,
  PATH_SETUP_PASSWORD,
} from '@configs';
import {
  AddressItem,
  AuthAutocompleteData,
  AutocompleteItem,
  DistrictRoot,
  ProvinceRoot,
  RegisterData,
  RootResponse,
  SellerItem,
  UploadedImageRoot,
  UploadImage,
  WardRoot,
  WarehouseItem,
} from '@interfaces';
import {
  selectApp,
  selectAuth,
  setLoading,
  setRegisterInfo,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp } from '@utils';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import { yupResolver } from '@hookform/resolvers/yup';
//@ts-ignore
import { NotificationContainer, NotificationManager } from 'react-notifications';

const schema = yup.object().shape({
  seller_id: yup.string().required('Vui lòng chọn seller'),
  full_name: yup.string().required('Vui lòng nhập tên'),
  phone_number: yup.string().required('Vui lòng nhập số điện thoại'),
  address_detail: yup.string().required('Vui lòng nhập địa chỉ chi tiết'),
  province_code: yup.string().required('Vui lòng chọn tỉnh/thành phố'),
  district_code: yup.string().required('Vui lòng chọn quận/huyện'),
  ward_code: yup.string().required('Vui lòng chọn phường/xã'),
  warehouse_id: yup.string().required('Vui lòng chọn kho'),
});

export const SignUpModule = ({ isUpdate }: { isUpdate: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { themeMode } = useAppSelector(selectApp);
  const { registerHash, registerInfo } = useAppSelector(selectAuth);
  const [avatar, setAvatar] = useImmer<UploadImage>({
    image_id: registerInfo?.image_id || '',
    url: registerInfo?.image_url || '',
    file: null,
  });
  const [agreePolicy, setAgreePolicy] = useState<boolean>(false);
  const [autocompleteData, setAutoCompleteData] = useImmer<AuthAutocompleteData>({
    provinces: [],
    districts: [],
    wards: [],
    warehouses: [],
    sellers: [],
  });
  const [imageError, setImageError] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      //gia tri cac o input
      seller_id: registerInfo?.seller_id || '',
      full_name: registerInfo?.full_name || '',
      phone_number: registerInfo?.phone_number || '',
      address_detail: registerInfo?.address?.detail || '',
      province_code: registerInfo?.province?.code || '',
      district_code: registerInfo?.district?.code || '',
      ward_code: registerInfo?.ward?.code || '',
      warehouse_id: registerInfo?.warehouse?.id || '',
      //gia tri luu lai khi chuyen page
      province: registerInfo?.province || DEFAULT_ADDRESS_ITEM,
      district: registerInfo?.district || DEFAULT_ADDRESS_ITEM,
      ward: registerInfo?.ward || DEFAULT_ADDRESS_ITEM,
      seller: registerInfo?.seller || DEFAULT_SELLER_ITEM,
      warehouse: registerInfo?.warehouse || DEFAULT_WAREHOUSE_ITEM,
    },
  });
  const handleRedirectToLogin = () => {
    navigate(PATH_LOGIN);
  };

  const handleChangeAgreePolicy = debounce(() => {
    setAgreePolicy(!agreePolicy);
  }, 500);

  const handleSignUp = handleSubmit(
    async (value) => {
      if (!avatar.file && !avatar.image_id) {
        setImageError('Vui lòng cập nhật logo hoặc ảnh cửa hàng');
        return;
      }
      try {
        dispatch(setLoading(true));
        const res = await uploadImages();
        const imageId = res?.data?.[0].id || avatar?.image_id;
        const imageUrl = res?.data?.[0].url || avatar?.url;
        const {
          full_name,
          phone_number,
          address_detail,
          province,
          district,
          ward,
          seller,
          warehouse,
          seller_id,
        } = value;
        if (!isUpdate) {
          const payload: RegisterData = {
            seller_id: seller?.id,
            full_name,
            phone_number,
            image_id: imageId || '',
            province,
            district,
            image_url: imageUrl || '',
            ward,
            address: {
              province_code: province.code,
              district_code: district.code,
              ward_code: ward.code,
              detail: address_detail,
            },
            warehouse,
            seller,
          };
          dispatch(setRegisterInfo(payload));
          navigate(PATH_SETUP_PASSWORD);
        } else {
          const updatePayload = {
            full_name,
            phone_number,
            image_id: imageId,
            address: {
              province_code: province?.code || '',
              district_code: district?.code || '',
              ward_code: ward?.code || '',
              detail: address_detail || '',
            },
            seller_id,
          };
          const res = await authAPI.updateProfile(updatePayload, registerInfo?.customer_id || '');
          NotificationManager.success(
            'Thông báo',
            'Thành công! Vui lòng chờ quản trị viên phê duyệt',
          );
        }
      } catch (error) {
        LogApp(error, 'err');
      } finally {
        dispatch(setLoading(false));
      }
    },
    (e) => {
      LogApp(e, 'eeee');
    },
  );
  const onSelectAddress = (value: any, key: any) => {
    setValue(key, value);
  };
  const onSelectProvince = async (value: AddressItem) => {
    try {
      onSelectAddress(value.code, 'province_code');
      onSelectAddress(value, 'province');
      const res: DistrictRoot = await authAPI.getDistricts(value.code);
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.districts.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.districts = optionValues;
      });
    } catch (error) {}
  };
  const onSelectDistrict = async (value: AddressItem) => {
    try {
      onSelectAddress(value.code, 'district_code');
      onSelectAddress(value, 'district');
      const res: WardRoot = await authAPI.getWards(value.code);
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.wards.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.wards = optionValues;
      });
    } catch (error) {}
  };
  const onSelectWard = (value: AddressItem) => {
    onSelectAddress(value.code, 'ward_code');
    onSelectAddress(value, 'ward');
  };
  const onSelectWarehouse = async (value: WarehouseItem) => {
    try {
      setValue('warehouse', value);
      setValue('warehouse_id', value.id);
      const res: RootResponse<SellerItem[]> = await authAPI.getAllSeller();
      const optionValues: AutocompleteItem<SellerItem>[] = res.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.sellers = optionValues;
      });
    } catch (error) {}
  };
  const onSelectSeller = (value: SellerItem) => {
    setValue('seller', value);
    setValue('seller_id', value.id);
  };
  const getAutocompleteData = async () => {
    try {
      dispatch(setLoading(true));
      const res: ProvinceRoot = await authAPI.getProvinces();
      const warehouse: RootResponse<WarehouseItem[]> = await authAPI.getWarehouses();
      if (registerInfo?.address?.province_code) await onSelectProvince(registerInfo?.province);
      if (registerInfo?.address?.district_code) await onSelectDistrict(registerInfo?.district);
      if (registerInfo?.address?.ward_code) onSelectWard(registerInfo?.ward);
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.provinces.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      const warehouseValues: AutocompleteItem<WarehouseItem>[] = warehouse.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.provinces = optionValues;
        draft.warehouses = warehouseValues;
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setLoading(false));
    }
  };
  const uploadImages = async (): Promise<UploadedImageRoot | void> => {
    if (!avatar.file) return;
    try {
      const form = new FormData();
      form.append('files', avatar.file);
      const res: UploadedImageRoot = await authAPI.uploadImages(form);
      return res;
    } catch (error) {
      LogApp(error);
      throw error;
    }
  };
  const changeSelectedImage = (value: any) => {
    setAvatar((draft) => {
      draft.file = value;
      draft.url = value?.name;
    });
  };
  useEffect(() => {
    getAutocompleteData();
  }, []);
  return (
    <>
      <SignUpForm
        register={register}
        redirectToLogin={handleRedirectToLogin}
        errors={errors}
        handleSignUp={handleSignUp}
        agreePolicy={agreePolicy}
        changeAgreePolicy={handleChangeAgreePolicy}
        setValue={setValue}
        watch={watch}
        onSelectProvince={onSelectProvince}
        onSelectDistrict={onSelectDistrict}
        onSelectWard={onSelectWard}
        autocompleteData={autocompleteData}
        registerInfo={registerInfo}
        changeSelectedImage={changeSelectedImage}
        imageError={imageError}
        onSelectWarehouse={onSelectWarehouse}
        onSelectSeller={onSelectSeller}
        isUpdate={isUpdate}
      />
    </>
  );
};
