//@ts-nocheck
import { IMAGES } from '@assets';
import { HEADER_HEIGHT, MAIN_THEME_DATA } from '@configs';
import { selectApp, useAppSelector } from '@redux';
import styled from 'styled-components';
import { SharedImage } from '../shared';

interface IProps {
  children: JSX.Element;
  hasBackgroundImage?: boolean;
}

export const IntroSection = (props: IProps) => {
  const { children, hasBackgroundImage = true } = props;
  const { themeData } = useAppSelector(selectApp);
  return (
    <StyledIntro hasBackgroundImage={hasBackgroundImage}>
      <div className="auth-block">
        {hasBackgroundImage && <SharedImage containerClassName="bg-image" src={IMAGES.authImg} />}
        <div className="form-block">
          <div className="auth-section">{children}</div>
        </div>
      </div>
    </StyledIntro>
  );
};

export const StyledIntro = styled.div<{ hasBackgroundImage?: boolean }>`
  width: 100%;
  height: 100%;
  /* min-height: calc(100vh - ${HEADER_HEIGHT}); */
  height: 100%;
  background-color: ${MAIN_THEME_DATA.bgColor};
  .auth-section {
    display: flex;
    justify-content: center;
  }
  .auth-block {
    display: flex;
    /* background-color: ${MAIN_THEME_DATA.mainColor}; */
    background: var(--Gr, linear-gradient(180deg, #ffc3bf 0%, #ff3023 100%));
    padding-top: 2rem;
    .form-block {
      width: ${(p) => (p?.hasBackgroundImage ? 'calc(100% - 58.6rem)' : '100%')};
      padding-top: 6.4rem;
    }
  }
  .bg-image {
    width: fit-content;
    height: fit-content;
    img {
      width: 58.6rem;
      aspect-ratio: 586/574;
      object-fit: contain;
    }
  }
`;
