import { categoryAPI, homeAPI } from '@api';
import { CategorySection, MobileCategoryListSection } from '@components';
import { PATH_CATEGORY, PATH_PRODUCT } from '@configs';
import {
  Category,
  CategoryDetailRoot,
  CategoryRoot,
  ProductFilter,
  ProductFilterRoot,
  SubCategory,
} from '@interfaces';
import { useAppDispatch, useAppSelector } from '@redux';
import { LogApp, useMediaQuery } from '@utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  selectHome,
  setCurrentChildCategory,
  setCurrentParentCategory,
} from 'src/redux/Slices/home';

export const MobileCategoryModule = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const dispatch = useAppDispatch();
  const { category } = useAppSelector(selectHome);
  const { currentChildCategory } = useAppSelector(selectHome);
  const navigate = useNavigate();
  const { id } = useParams();
  const [subCategory, setSubCategory] = useState<Category>({
    id: '',
    name: '',
    image: null,
    sub_categories: [],
  });
  const navigateToCategoryDetail = (id: string, val: SubCategory) => {
    // dispatch(setCurrentParentCategory(item));
    dispatch(setCurrentChildCategory(val));
    navigate(PATH_CATEGORY + `/${id}`);
  };

  const getTabItems = () => {
    return category.map((item) => {
      return {
        key: item.id,
        label: item.name,
        image: item.image,
      };
    });
  };
  const onChangeSubCategory = (key: string) => {
    const foundIndex = category.findIndex((item) => item.id === key);
    if (foundIndex !== -1) {
      dispatch(setCurrentChildCategory(category[foundIndex]));
      getCategory(key);
    }
  };
  const getCategory = async (id: string) => {
    try {
      const res: CategoryDetailRoot = await categoryAPI.getCategory(id);
      setSubCategory(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    getCategory(category?.[0]?.id);
  }, []);
  return (
    <MobileCategoryListSection
      childTabItems={subCategory.sub_categories}
      defaultActiveKey={category?.[0]?.id ?? ''}
      tabItems={getTabItems()}
      category={category}
      navigateToCategoryDetail={navigateToCategoryDetail}
      onChangeSubCategory={onChangeSubCategory}
    />
  );
};
