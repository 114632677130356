import { orderAPI } from '@api';
import { OrderDetailSection } from '@components';
import { DEFAULT_ADDRESS, DEFAULT_ORDER_DETAIL, PATH_ORDER, PATH_ORDER_PROGRESS } from '@configs';
import { Order, OrderDetailRoot, PromotionItem, PromotionType } from '@interfaces';
import { LogApp, toVND } from '@utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { STEP_STATUSES } from 'src/components/cart/components/steps/constants';
import { IStep } from 'src/components/cart/components/steps/stepper-component/types';
//@ts-ignore
import { NotificationManager } from 'react-notifications';
import { setLoading, useAppDispatch } from '@redux';
export const OrderDetailModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [order, setOrder] = useState<Order>(DEFAULT_ORDER_DETAIL);
  const [isLoading, setIsLoading] = useState(true);
  const getOrder = async () => {
    try {
      const res: OrderDetailRoot = await orderAPI.getOrder(id || '');
      setOrder(res.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const goBack = () => {
    navigate(-1);
  };
  const getOrderAddress = () => {
    if (order?.to_address?.detail)
      return `${order?.to_address?.detail}, ${order?.to_address?.wards?.ward_name}, ${order?.to_address?.districts?.district_name}, ${order?.to_address?.provinces?.province_name}`;
    return `${order?.to_address?.wards?.ward_name}, ${order?.to_address?.districts?.district_name}, ${order?.to_address?.provinces?.province_name}`;
  };
  const getOderTimeline = (): IStep[] => {
    return order.order_histories.map((item, index) => {
      return {
        label: item.action,
        time: moment(item.created_at).format('HH:mm DD/MM/YYYY'),
        status: STEP_STATUSES.COMPLETED,
      };
    });
  };
  const calculateAmountPromotionDiscountPercent = (
    conditionAmount: number,
    promotions: PromotionItem[],
  ) => {
    let maxDiscountPercent = 0;
    for (const promotion of promotions) {
      if (conditionAmount >= promotion.condition_amount) {
        maxDiscountPercent = Math.max(maxDiscountPercent, promotion.discount_percent);
      }
    }
    return maxDiscountPercent;
  };
  const getDiscountPercent = (index: number) => {
    const orderItem = order.order_items?.[index];
    const proItem = orderItem?.promotions?.[0];
    if (proItem) {
      if (Number(proItem.type) === PromotionType.AMOUNT)
        return calculateAmountPromotionDiscountPercent(
          orderItem?.amount || 0,
          proItem?.promotion_items || [],
        );
      return proItem?.discount_percent;
    }
    return 0;
  };
  const getTotalDeductPercent = () => {
    return toVND(order.money_deducted);
  };
  const calculateTotalPrice = () => {
    const totalPrice = order.order_items.reduce((acc, product) => {
      return acc + product.price * product.amount;
    }, 0);
    return totalPrice;
  };
  const navigateToOrderProgress = (id: string) => {
    navigate(`${PATH_ORDER_PROGRESS}/${id}`);
  };
  const cancelOrder = async () => {
    try {
      dispatch(setLoading(true));
      const res = await orderAPI.cancelOrder(id || '');
      navigate(-1);
      NotificationManager.success('Thông báo', 'Huỷ đơn hàng thành công');
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    getOrder();
  }, []);
  return (
    <OrderDetailSection
      isLoading={isLoading}
      getOrderAddress={getOrderAddress}
      goBack={goBack}
      order={order}
      orderTimeline={getOderTimeline()}
      navigateToOrderProgress={navigateToOrderProgress}
      getTotalDeductPercent={getTotalDeductPercent}
      getDiscountPercent={getDiscountPercent}
      calculateTotalPrice={calculateTotalPrice}
      cancelOrder={cancelOrder}
    />
  );
};
