import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { cartAPI } from '@api';
import { DEFAULT_CLASSIFICATION_PARAMS, DEFAULT_SUB_CLASSIFICATION_PARAMS } from '@configs';
import { CartItem, CartRoot, Classification, SubClassification } from '@interfaces';
import { RootState } from '.';
import { LogApp } from '@utils';

interface IInitialState {
  loading: boolean;
  cart: CartItem[];
  classification: {
    parent: Classification;
    child: SubClassification;
  };
  orderHash: string;
}
const initialState = {
  cart: [],
  loading: false,
  classification: {
    parent: DEFAULT_CLASSIFICATION_PARAMS,
    child: DEFAULT_SUB_CLASSIFICATION_PARAMS,
  },
  orderHash: '',
} as IInitialState;
export const getCart = createAsyncThunk('cart/list', async (warehouseId: string, { getState }) => {
  const authState = getState() as any;
  const response: CartRoot = await cartAPI.getCart(authState.auth.privateId.userId, warehouseId);
  return response;
});
const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<{ item: CartItem; quantity: number }>) => {
      const foundIndex = state.cart.findIndex((item) => {
        if (item.product_item_id)
          return item?.product_item_id === action.payload.item.product_item_id;
        return item?.product_id === action.payload.item.product_id;
      });
      if (foundIndex !== -1) state.cart[foundIndex].amount += action.payload.quantity;
      else state.cart.push(action.payload.item);
    },
    increaseQuantity: (state, action: PayloadAction<{ quantity: number; item: CartItem }>) => {
      const foundIndex = state.cart.findIndex((item) => {
        if (item.product_item_id)
          return item?.product_item_id === action.payload.item.product_item_id;
        return item?.product_id === action.payload.item.product_id;
      });
      if (foundIndex !== -1) state.cart[foundIndex].amount += action.payload.quantity;
      else {
        state.cart.push(action.payload.item);
      }
    },
    decreaseQuantity: (state, action: PayloadAction<{ quantity: number; item: CartItem }>) => {
      const foundIndex = state.cart.findIndex((item) => {
        if (item.product_item_id)
          return item?.product_item_id === action.payload.item.product_item_id;
        return item?.product_id === action.payload.item.product_id;
      });
      if (foundIndex !== -1) {
        if (state.cart[foundIndex].amount === 1) state.cart.splice(foundIndex, 1);
        else state.cart[foundIndex].amount -= action.payload.quantity;
      }
    },
    changeQuantity: (state, action: PayloadAction<{ quantity: number; item: CartItem }>) => {
      const foundIndex = state.cart.findIndex((item) => {
        if (item.product_item_id)
          return item?.product_item_id === action.payload.item.product_item_id;
        return item?.product_id === action.payload.item.product_id;
      });
      if (foundIndex !== -1) state.cart[foundIndex].amount = action.payload.quantity;
    },
    updateParentClassification: (
      state,
      action: PayloadAction<{ cartItem: CartItem; classification: Classification }>,
    ) => {
      const cartIndex = state.cart.findIndex((item) => {
        if (item.product_item_id)
          return item?.product_item_id === action.payload.cartItem.product_item_id;
        return item?.product_id === action.payload.cartItem.product_id;
      });
      if (cartIndex !== -1) {
        state.cart[cartIndex].selectedClassifications.parent =
          action.payload.classification || DEFAULT_CLASSIFICATION_PARAMS;
      }
    },
    updateChildClassification: (
      state,
      action: PayloadAction<{ cartItem: CartItem; classification: SubClassification }>,
    ) => {
      const cartIndex = state.cart.findIndex((item) => {
        if (item.product_item_id)
          return item?.product_item_id === action.payload.cartItem.product_item_id;
        return item?.product_id === action.payload.cartItem.product_id;
      });
      if (cartIndex !== -1) {
        state.cart[cartIndex].selectedClassifications.child =
          action.payload.classification || DEFAULT_CLASSIFICATION_PARAMS;
        state.cart[cartIndex].product_item_id = action.payload.classification.product_item.id;
      }
    },
    deleteCartItem: (state, action: PayloadAction<{ item: CartItem }>) => {
      const foundIndex = state.cart.findIndex((item) => {
        if (item.product_item_id)
          return item?.product_item_id === action.payload.item.product_item_id;
        return item?.product_id === action.payload.item.product_id;
      });
      state.cart.splice(foundIndex, 1);
    },
    updateOrderHash: (state, action: PayloadAction<string>) => {
      state.orderHash = action.payload;
    },
    clearCart: (state) => {
      state.cart = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, (state) => {
        state.loading = !state.cart.length;
      })
      .addCase(getCart.fulfilled, (state, { payload }) => {
        const data = payload.data.items;
        state.loading = false;
        state.cart = data.map((val) => {
          // let foundItem: CartItem = DEFAULT_CART_ITEM_PARAMS;
          // let foundClassItem: Classification = DEFAULT_CLASSIFICATION_PARAMS;
          // let foundSubClassItem: SubClassification = DEFAULT_SUB_CLASSIFICATION_PARAMS;
          // const foundItemIndex = data.findIndex(
          //   (item) => item.product_item_id === val.product_item_id
          // );
          // if (foundItemIndex !== -1) foundItem = data[foundItemIndex];
          // const foundClassIndex = foundItem.classifications.findIndex(
          //   (item) => item.id === (val.classification_parent_id || val.classification_id)
          // );
          // if (foundClassIndex !== -1) foundClassItem = foundItem.classifications[foundClassIndex];
          // const foundSubClassIndex = foundClassItem.sub_classifications.findIndex(
          //   (item) => item.id === val.classification_id
          // );
          // if (foundSubClassIndex !== -1)
          //   foundSubClassItem = foundClassItem.sub_classifications[foundSubClassIndex];
          return {
            ...val,
            selectedClassifications: {
              parent: {
                ...DEFAULT_CLASSIFICATION_PARAMS,
                id: val.classification_parent_id ?? '',
                value: val.classification_parent_name ?? '',
                product_item: {
                  ...DEFAULT_CLASSIFICATION_PARAMS.product_item,
                  deduct_percent: val.deduct_percent,
                },
              },
              child: {
                ...DEFAULT_SUB_CLASSIFICATION_PARAMS,
                id: val.classification_id ?? '',
                value: val.classification_name ?? '',
                product_item: {
                  ...DEFAULT_SUB_CLASSIFICATION_PARAMS.product_item,
                  deduct_percent: val.deduct_percent,
                },
              },
            },
          };
        });
      })
      .addCase(getCart.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectCart = (state: RootState) => state.cart;

export const {
  addToCart,
  increaseQuantity,
  decreaseQuantity,
  updateParentClassification,
  updateChildClassification,
  deleteCartItem,
  updateOrderHash,
  changeQuantity,
  clearCart,
} = cart.actions;

export default cart.reducer;
