import { memo } from 'react';

import { IMAGES } from '@assets';
import { SharedButton, SharedImage } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import styled from 'styled-components';

interface IProps {
  error: string;
}

export const ErrorPage = memo((props: IProps) => {
  const { error } = props;
  return (
    <StyledErrorPage>
      <div className="wrapper">
        <SharedImage containerClassName="not-found-img" src={IMAGES.error} />
        <span className="error">Đã có lỗi xảy. Vui lòng liên hệ sale để được hỗ trợ</span>
        <SharedButton
          typeHtml="button"
          text="Quay về trang chủ"
          backgroundColor={MAIN_THEME_DATA.mainColor}
          btnStyle="pad"
          onClick={() => {
            window.location.href = '/';
          }}
        />
      </div>
    </StyledErrorPage>
  );
});

export const StyledErrorPage = styled.div`
  background-color: rgb(255, 244, 243);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0 2rem;
    .btn {
      width: unset;
    }
    @media (max-width: 768px) {
      .btn {
        width: 100%;
      }
    }
    .not-found-img {
      @media (max-width: 768px) {
        width: 30rem;
        .btn {
          width: 100%;
        }
      }
      width: 50rem;
      aspect-ratio: 1;
    }
    .error {
      display: inline-block;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: #000;
      font-family: Roboto-Medium;
      margin-bottom: 3.2rem;
      @media (max-width: 768px) {
        font-size: 1.7rem;
      }
    }
  }
`;
